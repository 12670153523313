//import main library
    import $ from 'jquery';
    import jQuery from 'jquery';
    import 'jquery-migrate';
    import 'bootstrap';
    import '@popperjs/core';

//declare
    window.$ = $;
    window.jQuery = jQuery;
