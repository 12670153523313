import './bootstrap';
import './easing';
import 'isotope-layout';
import './justifiedGallery';
import './parallaxie';
import 'slick-carousel';
import './splitting';
import 'paroller.js';
import './youtube';
import './gsap';
import './scroll-trigger';
import './scroll-smoother';
import 'jquery-circle-progress';
import './countrup';
import '~node/waypoints/src/waypoint';
import 'magnific-popup';
import './hover3d';
import './scroll-it';
import './headline';
import './sticky-kit';


$(function () {
    var width = $(window).width();
    if (width > 991) {
  
      /* ===============================  scroll  =============================== */
  
      gsap.registerPlugin(ScrollTrigger);
  
      let sections = gsap.utils.toArray(".panel");
  
      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".thecontainer",
          pin: true,
          scrub: 1,
          // snap: 1 / (sections.length - 1),
          end: () => "+=" + document.querySelector(".thecontainer").offsetWidth
        }
      });
  
    }
  });